// extracted by mini-css-extract-plugin
export var headerHome = "blog-module--header-home--1OAIo";
export var homeSocials = "blog-module--home-socials--R87Tv";
export var homeSocialsItem = "blog-module--home-socials-item--NEAdf";
export var blogDescription = "blog-module--blog-description--14ihN";
export var projectsPosts = "blog-module--projects-posts--3bIQH";
export var projectBtn = "blog-module--project-btn--2e6U6";
export var projectsStack = "blog-module--projects-stack--1XoyK";
export var projectsStackItem = "blog-module--projects-stack-item--LhrGw";
export var projectsPostsList = "blog-module--projects-posts-list--8lXrM";
export var projectsPostItem = "blog-module--projects-post-item--2BlV0";
export var blogPosts = "blog-module--blog-posts--1jnrj";
export var blogPostsList = "blog-module--blog-posts-list--2c6_d";
export var blogPostItem = "blog-module--blog-post-item--8J4nh";
export var blogPostTitle = "blog-module--blog-post-title--3KQLa";
export var blogPostDate = "blog-module--blog-post-date--vxyHS";
export var blogExcerpt = "blog-module--blog-excerpt--hbrjv";
export var blogLink = "blog-module--blog-link--Us47L";
export var blogCategory = "blog-module--blog-category--2c-Vw";
export var tagCategory = "blog-module--tag-category--3pgLN";
export var contactHome = "blog-module--contact-home--1RLe5";
export var profileHome = "blog-module--profile-home--3vd-d";
export var profileInfo = "blog-module--profile-info--2ZoEd";
export var profileEmail = "blog-module--profile-email--38c2r";
export var profileSocials = "blog-module--profile-socials--3q-Iv";
export var profileSocialsItem = "blog-module--profile-socials-item--1rEN3";
export var madeWith = "blog-module--made-with--2-PSu";
export var archiveList = "blog-module--archive-list--1WNIz";
export var profilePic = "blog-module--profile-pic--h_DnW";